/*
 * @Author: wjc
 * @Date: 2023-05-25 16:51:28
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 14:46:30
 * @Description:
 */
import md5 from 'crypto-js/md5'
import type { ChunkList } from '~/models/enterprise'

/**
 * 打开新标签页
 * @param href 链接
 */
export function openNew(href: string) {
  let newWin = window.open('about:blank', '_blank')
  newWin!.location.href = href
  newWin = null
}

/**
 * @description MD5加密字符串
 * @param code 加密目标
 * @returns string
 */
export const encryptByMd5 = (code: string) => {
  return md5(code).toString().toUpperCase()
}

/**
 * @description 校验手机号码
 * @param string value 值
 */
export const testMobile = (value: string) => {
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)
}

/**
 * @description 校验邮箱
 * @param string value 值
 */
export const checkEmail = (value: string) => {
  return /^[a-zA-Z0-9]+([a-zA-Z0-9-_.]*)@([a-zA-Z0-9]+[-|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/g.test(
    value
  )
}

/**
 * @description 校验固定电话
 * @param string value 值
 */
export const testPhone = (value: string) => {
  return /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value)
}

/**
 * @description 校验身份证18位
 * @param string value 值
 */
export const testIdCard = (value: string) => {
  return /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
    value
  )
}

/**
 * 利用Canvas生成设备指纹
 */
export function getCanvasFingerprint(): string {
  const canvas = document.createElement('canvas') as HTMLCanvasElement
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
  const txt = 'BrowserLeaks,com <canvas> 1.0'

  ctx.textBaseline = 'top'
  ctx.font = "14px 'Arial'"
  ctx.textBaseline = 'alphabetic'
  ctx.fillStyle = '#f60'
  ctx.fillRect(125, 1, 62, 20)
  ctx.fillStyle = '#069'
  ctx.fillText(txt, 2, 15)
  ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
  ctx.fillText(txt, 4, 17)

  const canvasImageData = canvas.toDataURL()

  return encryptByMd5(canvasImageData)
}

/**
 * 复制字符串到剪贴板
 */
export async function copyToClipboard(text: string) {
  try {
    // 优先调用浏览器的复制方法
    return window.navigator.clipboard.writeText(text)
  } catch {
    const element = document.createElement('textarea')
    const previouslyFocusedElement = document.activeElement

    element.value = text

    // Prevent keyboard from showing on mobile
    element.setAttribute('readonly', '')

    element.style.contain = 'strict'
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    element.style.fontSize = '12pt' // Prevent zooming on iOS

    const selection = document.getSelection()
    const originalRange = selection ? selection.rangeCount > 0 && selection.getRangeAt(0) : null

    document.body.appendChild(element)
    element.select()

    // Explicit selection workaround for iOS
    element.selectionStart = 0
    element.selectionEnd = text.length

    document.execCommand('copy')
    document.body.removeChild(element)

    if (originalRange) {
      selection!.removeAllRanges() // originalRange can't be truthy when selection is falsy
      selection!.addRange(originalRange)
    }

    // Get the focus back on the previously focused element, if any
    if (previouslyFocusedElement) {
      ; (previouslyFocusedElement as HTMLElement).focus()
    }
  }
}

export function arrayChunk<T>(source: T[] = [], chunk = 3): ChunkList<T>[] {
  if (isNaN(Number(chunk))) {
    return []
  }
  if (Object.prototype.toString.call(source) !== '[object Array]') {
    return []
  }
  const result = []
  for (let i = 0, j = source.length; i < j; i += chunk) {
    result.push(source.slice(i, i + chunk))
  }

  return result.map((item: any, index) => {
    return {
      id: item.id || index,
      children: item,
    }
  })
}
